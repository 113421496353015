import { Component, Vue, Prop } from "vue-property-decorator";
import API from "@api";
import { cloneStructured } from "@helpers";
import { getFallbackCardGroup } from "@/lib/creaditCard";
import { CardActions } from "@store/modules/card/types";
import { cardModule, walletModule, profileModule } from "@store/namespaces";
import { CardBatchIssue, CardType } from "@/types/card";
import { WalletActions } from "@store/modules/wallet/types";
import { ProfileGetters } from "@store/modules/profile/types";

@Component
export default class BatchIssueCardsProgress extends Vue {
  @Prop({ type: [String, Number], default: "" }) private reloadKey!:
    | string
    | number;

  @Prop({ type: String, required: true }) private readonly cardType!: CardType;

  @cardModule.Action("fetchBatchIssueCardList")
  private readonly fetchBatchIssueCardListAction!: CardActions["fetchBatchIssueCardList"];
  @walletModule.Action("fetchWallets")
  private readonly fetchWalletsAction!: WalletActions["fetchWallets"];
  @profileModule.Getter("profileEmail")
  private readonly profileEmailGetter!: ProfileGetters["profileEmail"];

  private batchIssueCards: {
    list: Readonly<CardBatchIssue[]>;
    loading: boolean;
    cancelable: {
      value: null | CardBatchIssue;
      loading: boolean;
    };
  } = {
    list: [],
    loading: false,
    cancelable: {
      value: null,
      loading: false,
    },
  };

  private get isShowConfirmCancelBatchIssueCards() {
    return !!this.batchIssueCards.cancelable.value;
  }

  private set isShowConfirmCancelBatchIssueCards(showed) {
    this.batchIssueCards.cancelable.value = showed
      ? this.batchIssueCards.cancelable.value
      : null;
  }

  private toggleConfirmCancelBatchIssueCard(
    showed = !this.isShowConfirmCancelBatchIssueCards
  ) {
    this.isShowConfirmCancelBatchIssueCards = showed;
  }

  private onCancelBatchIssueCard(item: CardBatchIssue) {
    this.batchIssueCards.cancelable.value = cloneStructured(item);
  }

  private async cancelBatchIssueCard() {
    if (
      this.batchIssueCards.cancelable.loading ||
      !this.batchIssueCards.cancelable.value
    ) {
      return;
    }

    this.batchIssueCards.cancelable.loading = true;

    const { id } = this.batchIssueCards.cancelable.value;

    try {
      await API.card.batchIssueCardUpdate({
        id,
        isCanceled: true,
      });
      await this.fetchBatchIssueCardList();
      await this.fetchWalletsAction();

      this.batchIssueCards.cancelable.loading = false;
      this.toggleConfirmCancelBatchIssueCard(false);
    } catch {
      this.batchIssueCards.cancelable.loading = false;
    }
  }

  private getPercentProgress({
    numCardsIssued,
    numCardsTotal,
  }: CardBatchIssue) {
    return Math.round((numCardsIssued * 100) / numCardsTotal);
  }

  private getCardBatchIssueName(item: CardBatchIssue) {
    const { name } =
      item.cardGroup ||
      getFallbackCardGroup({
        ownerEmail: this.profileEmailGetter,
      });

    return name;
  }

  private async fetchBatchIssueCardList() {
    if (this.batchIssueCards.loading) return;

    this.batchIssueCards.loading = true;

    try {
      const batchIssueCards = await this.fetchBatchIssueCardListAction();

      this.batchIssueCards.list = Object.freeze(
        batchIssueCards.filter(
          ({ closedAt, cardType, canceled }) =>
            !closedAt && !canceled && this.cardType === cardType
        )
      );
      this.batchIssueCards.loading = false;
    } catch (error) {
      this.batchIssueCards.loading = false;
      this.batchIssueCards.list = [];
    }
  }

  private initBatchIssueCardList() {
    let intervalId = 0;

    this.$watch(
      "reloadKey",
      () => {
        this.fetchBatchIssueCardList();

        window.clearInterval(intervalId);

        intervalId = window.setInterval(async () => {
          if (this.batchIssueCards.loading) return;

          await this.fetchBatchIssueCardList();
          await this.$nextTick();

          if (!this.batchIssueCards.list.length) {
            window.clearInterval(intervalId);
          }
        }, 5e3);
      },
      { immediate: true }
    );

    const timeoutIds: Record<number, number> = {};

    this.$watch(
      () => {
        return this.batchIssueCards.list
          .filter(({ compeleted }) => compeleted)
          .map(({ id }) => id);
      },
      (completedIds) => {
        completedIds.forEach((completedId) => {
          if (timeoutIds[completedId] > -1) return;

          timeoutIds[completedId] = 0;

          API.card
            .batchIssueCardUpdate({
              id: completedId,
              isClosed: true,
            })
            .finally(() => {
              timeoutIds[completedId] = window.setTimeout(() => {
                this.batchIssueCards.list = Object.freeze(
                  this.batchIssueCards.list.filter(
                    ({ id }) => id !== completedId
                  )
                );

                this.$emit("completed");

                delete timeoutIds[completedId];
              }, 2e3);
            });
        });
      }
    );

    this.$watch(
      () => {
        return this.batchIssueCards.list.reduce<string>(
          (str, { numCardsIssued, id }) => {
            return (str += `${id}_${numCardsIssued}_`);
          },
          ""
        );
      },
      () => {
        this.$emit("updated");
      }
    );

    this.$once("hook:beforeDestroy", () => {
      window.clearInterval(intervalId);
      Object.values(timeoutIds).forEach((timeoutId) => {
        window.clearTimeout(timeoutId);
      });
    });
  }

  private mounted() {
    this.initBatchIssueCardList();
  }
}
