var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-expand-transition',[(_vm.batchIssueCards.list.length > 0)?_c('section',_vm._l((_vm.batchIssueCards.list),function(item){return _c('v-expand-transition',{key:item.id},[_c('div',[(_vm.$vuetify.breakpoint.mobile)?_c('header',{staticClass:"d-flex align-center font-weight-medium blue-grey--text text--darken-4 text-caption mb-2"},[(!item.compeleted)?_c('LottieBanner',{style:({
                marginRight: '-2px',
                marginLeft: '-10px',
                marginTop: '-10px',
                marginBottom: '-10px',
              }),attrs:{"src":"/img/lottie/hourglass.json","aspect-ratio":"1","max-width":"33"}}):_c('v-icon',{staticClass:"mr-2",attrs:{"size":"16","color":"blue-grey darken-4"}},[_vm._v(" $checkmark ")]),(item.compeleted)?_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(
                _vm.$vuetify.lang.t(
                  '$vuetify.dashboard.card.batch_issue.success',
                  _vm.getCardBatchIssueName(item)
                )
              )}}):_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(
                _vm.$vuetify.lang.t(
                  '$vuetify.dashboard.card.batch_issue.pending',
                  _vm.getCardBatchIssueName(item)
                )
              )}})],1):_vm._e(),_c('div',{class:[
              'd-flex',
              {
                'pb-6': !_vm.$vuetify.breakpoint.mobile,
                'pb-4': _vm.$vuetify.breakpoint.mobile,
              },
            ]},[_c('v-progress-linear',{class:[
                'progressbar',
                'flex-grow-1',
                {
                  progressbar_pending: !item.compeleted,
                  progressbar_success: item.compeleted,
                },
              ],attrs:{"value":_vm.getPercentProgress(item),"rounded":"","height":"40","color":item.compeleted ? '#45DF83' : '#FFC008'}},[(!_vm.$vuetify.breakpoint.mobile)?_c('div',{class:[
                  'flex-grow-1',
                  'd-flex',
                  'align-center',
                  'pr-6',
                  'font-weight-medium',
                  {
                    'blue-grey--text text--darken-4': !item.compeleted,
                    'white--text': item.compeleted,
                    'pl-6': item.compeleted,
                    'pl-2': !item.compeleted,
                  },
                ]},[(!item.compeleted)?_c('LottieBanner',{style:({ marginRight: '-4px' }),attrs:{"src":"/img/lottie/hourglass.json","aspect-ratio":"1","max-width":"43"}}):_c('v-icon',{staticClass:"mr-2",attrs:{"size":"20","color":"white"}},[_vm._v(" $checkmark ")]),(item.compeleted)?_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(
                    _vm.$vuetify.lang.t(
                      '$vuetify.dashboard.card.batch_issue.success',
                      _vm.getCardBatchIssueName(item)
                    )
                  )}}):_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(
                    _vm.$vuetify.lang.t(
                      '$vuetify.dashboard.card.batch_issue.pending',
                      _vm.getCardBatchIssueName(item)
                    )
                  )}})],1):_vm._e(),_c('strong',{class:[
                  'progressbar__percent',
                  {
                    'blue-grey--text text--darken-4': !item.compeleted,
                    'white--text': item.compeleted,
                  },
                ]},[_vm._v(" "+_vm._s(_vm.getPercentProgress(item))+"% ")])]),(!item.compeleted)?_c('v-btn',{staticClass:"ml-2 flex-shrink-0",attrs:{"color":"error","height":"40"},on:{"click":function($event){return _vm.onCancelBatchIssueCard(item)}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.fields.cancel"))+" ")]):_vm._e()],1)])])}),1):_vm._e()]),_c('ConfirmDialog',{attrs:{"loading":_vm.batchIssueCards.cancelable.loading,"max-width":440},on:{"confirm":_vm.cancelBatchIssueCard},scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.batchIssueCards.cancelable.value)?_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$vuetify.lang.t(
            '$vuetify.dashboard.card.batch_issue.confirm_cancel.title',
            `<b>${_vm.getCardBatchIssueName(
              _vm.batchIssueCards.cancelable.value
            )}</b>`
          )
        )}}):_vm._e()]},proxy:true}]),model:{value:(_vm.isShowConfirmCancelBatchIssueCards),callback:function ($$v) {_vm.isShowConfirmCancelBatchIssueCards=$$v},expression:"isShowConfirmCancelBatchIssueCards"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }